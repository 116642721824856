<template>
  <div class="p-follow-client">
    <!--    添加返回-->
    <!-- <go-back-item></go-back-item> -->
    <section>
      <div class="p-follow-client_box">
        <!-- <znl-input v-model="editItem.followType"
                   :select-options="followTypeOptions"
                   class="m-input-required"
                   border
                   form-type="select"
                   title="跟进方式:"
                   ref="followType_ref"
                   title-width="120px"
                   width="300px">
        </znl-input> -->
        <znl-input v-model="followup.followType"  :select-options="followTypeOptions"
                             border class="m-input-required" form-type="select"
                             title="跟进方式:"
                             title-width="100px"
                              ref="followType_ref"
                             width="300px"></znl-input>
        <znl-input v-model="editItem.followDate"
                   border
                   form-type="datepicker"
                value-format="yyyy-MM-dd HH:mm:ss"
                   title="跟进时间:"
                   title-width="120px"
                   width="300px">
        </znl-input>
        <znl-input v-model="editItem.followIsOrder"
                   :select-options="orderOptions"
                   @change="IsOrder"
                   border
                   class="m-input-required"
                   form-type="select"
                   title="是否准备下单:"
                   title-width="120px"
                   ref="followIsOrder_ref"
                   width="300px">
        </znl-input>

      </div>
      <div class="p-follow-client_box">
        <znl-input v-model="editItem.followPredictDate"
                   border
                   :class="temp"
                   
                   form-type="datepicker"
                       value-format="yyyy-MM-dd HH:mm:ss"
                   title="预计下单时间:"
                   title-width="120px"
                   width="300px">
        </znl-input>
        <znl-input v-model="editItem.followNextDate"
                   class="m-input-required"
                   border
                   form-type="datepicker"
                      value-format="yyyy-MM-dd HH:mm:ss"
                   title="计划下次跟进时间:"
                   title-width="120px"
                   width="300px">
        </znl-input>
      </div>
      <div></div>
    </section>
    <section class="p-client-edit-contacts">
      <div class="header">
        <p class="p-client-edit_title"><span>客户联系人与标签： </span></p>
        <p>
          <el-button type="primary" @click="onAddContact">新增联系人</el-button>
        </p>
      </div>

      <div>
        <el-tabs v-model="editableTabsValue" closable type="border-card" @tab-remove="onDeleteContact">
          <el-tab-pane v-for="item in editItem.contactList" :key="item.contactIndex" :label="`联系人${item.contactIndex}`"
                       :name="item.contactIndex">
            <section>
              <ul class="c-client-edit--ul">

                <li class="c-client-edit--li">
                  <znl-input v-model="item.contactName" border class="m-input-required"
                             title="联系人名称:"
                             title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactGender" :select-options="genderOptions"
                             border class="m-input-required" form-type="select"
                             title="性别:"
                             title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactBirthday" border class="m-input-required"
                             form-type="datepicker"
                                value-format="yyyy-MM-dd HH:mm:ss"
                             title="生日:"
                             title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactPhone" border class="m-input-required"
                             title="固定电话:" title-width="100px"
                             width="300px"></znl-input>
                              </li>
                     <li class="c-client-edit--li">         
                  <znl-input v-model="item.contactMobilePhone" border class="m-input-required"
                             title="手机:" title-width="100px"
                             width="300px"></znl-input>
                </li>
                <li class="c-client-edit--li">
                  <znl-input v-model="item.contactQQ" border title="QQ:" title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactWechat" border title="微信:" title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactWW" border title="旺旺:" title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactEmail" border title="Email:" title-width="100px"
                             width="300px"></znl-input>
                </li>
                <li class="c-client-edit--li">
                  <znl-input v-model="item.contactSalesQQ" border title="对接业务QQ:"
                             title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactSalesWechat" border title="对接业务微信:"
                             title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactSalesWW" border title="对接业务旺旺:"
                             title-width="100px"
                             width="300px"></znl-input>
                  <znl-input v-model="item.contactSalesEmail" border title="对接业务Email:"
                             title-width="100px"
                             width="300px"></znl-input>
                </li>

              </ul>
              <ul class="c-client-edit--ul">
                <li class="c-client-edit--li-title">
                  <span>联系人标签</span>
                </li>
                <li v-for="(m) in item.contactTagList" :key="m.id" class="c-client-edit--li"
                    style="display: inline-block">
                  <div :class="{'m-input-required':m.isRequired,
                      'm-select-input-score':(m.isScores &&  m.selectValue!=='' && m.isClickAdd!==true && ((m.code==='ClientCharacter' && CanPersonalitySign) || (m.code!='ClientCharacter' && CanSign))) ,
                  'm-select-attrinput-score':(m.isScores && ((m.code==='ClientCharacter' && !CanPersonalitySign) || (m.code!='ClientCharacter' && !CanSign) || m.selectValue==='' || m.isClickAdd))
                  }">
                    <znl-input v-model="m.selectValue"
                               :select-options="m.lines"
                               :title="`${m.name}:`"
                               border
                                  :multiple="m.Multiple"
                               form-type="select"
                               title-width="115px"
                               width="300px">
                    </znl-input>
                    <span v-if="m.isScores" class="m-select-span-score">分</span>
                    <span
                      v-if="m.isScores && m.selectValue!=='' && m.isClickAdd!==true && ((m.code==='ClientCharacter' && CanPersonalitySign) || (m.code!=='ClientCharacter' && CanSign))"
                      class="m-select-span-add" @click="LableAddOne(m,item.ContactId)">+</span>
                  </div>
                </li>

              </ul>
            </section>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
    <section>
      <znl-input v-model="editItem.followRemark"
                 class="m-input-required"
                 :rows="5"
                 border
                 rowIndex="3"
                 show-word-limit
                 title="跟进内容:"
                 title-width="100px"
                 type="textarea"
                 width="600px">
      </znl-input>
    </section>
    <section class="p-client-edit-inquire">
      <div class="header">
        <span style="line-height:32px;">询价记录： </span>
        <!-- <el-button type="primary" @click="ImportExcel">导入Excel</el-button> -->
        <!-- <input type="file" @change="importf(this)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> -->
        <el-upload
          ref="uploadExcel"
          :before-upload="beforeExcel"
          :http-request="uploadExcel"
          :limit="1"
          :on-exceed="exceedExcel"
          accept=".xlsx,.xls"
          action=""
          class="upload-excel">
          <el-button icon="el-icon-upload" size="small" type="primary">导入Excel</el-button>
        </el-upload>
        <el-button size="small"  class="inquire-btn-importimg" type="primary" icon="el-icon-upload" @click="ImportImgs">附件上传</el-button>
          <!-- <el-upload
           class="upload-demo"
           :on-preview="handlePreview"
           :on-remove="handleRemove"
           :http-request="uploadImgs"
           :file-list="fileList"
           list-type="picture">
        <el-button size="small" type="primary" :style="{ display: visibleLine }" class="imgstyle" >附件上传</el-button>
        </el-upload> -->
      </div>
      <div v-if="showInquireTable" class="inquire-table">

        <grid
          ref="listinquire"
          :columns="columns"
          :datas="itemSource"
          :page-index="pagination.page"
          :page-size="pagination.limit"
          :total-count="pagination.total"
          headerHeight="35"
          type="base">
        </grid>
      </div>
      <div v-else class="inquire-imgs">
        <el-upload
          ref="uploadImgs"
          :file-list="imgList"
          class="upload-file"
          :http-request="uploadImgs"
          :limit="20"
          :on-exceed="exceedImg"
          :on-preview="handlePictureCardPreview"
          :before-remove="handleBeforeRemove"
          :on-remove="handleRemove"
          :on-change="handFileChange"
          :on-success="handleAvatarSuccess"
          :auto-upload="false"
          accept=".png,.jpg,.jpeg,.xls,.xlsx"
          action=""
          list-type="picture-card">

          <i class="el-icon-plus"></i>
        </el-upload>
        <znl-input v-for="(item, i) in imgRemarkList" :key="i" v-model="item.val"
           border
           placeholder="备注"
           width="150px">
        </znl-input>
        <el-dialog :visible.sync="dialogVisible">
          <img :src="dialogImageUrl" alt="" width="100%">
        </el-dialog>

      </div>
    </section>

    <section class="p-client-edit-footer" style="text-align: right">
      <!-- <el-button size="medium" type="primary" @click="testHunder">测试按钮</el-button> -->
      <el-button :loading="saveLoading" size="medium" type="primary" class="followbtn" @click="followUPSubmit">保存</el-button>
    </section>
  </div>
</template>
<script>
import {getClientContactInfo,getFollowUpInfo, getTagAndAttribute,OnUploadPicture, editFollowClientInfo} from "@/api/client";
import {followMap, followContactMap, inquireColumns} from "./Modules/FollowClient/dataMap";
import {setRequiredOrScore, initRequiredCodes, initScoresCodes} from "./script/form";
import XLSX from 'xlsx'

const followRe = /^follow/

export default {
  name: 'followClient',
  data() {
    return {
      //询价记录列表表头
      columns: inquireColumns,
      visibleLine: '', //隐藏
      //询价记录列表数据源
      itemSource: [],
      //询价记录列表翻页
      pagination: {
        page: 1,
        limit: 20,
        total: 40,
      },
      showInquireTable: true,//是否显示询价表格
      dialogImageUrl: '',//大图路径
      dialogVisible: false,//是否查看大图
      imgList: [],
      imgRemarkList: [],
      InquireImage:[],
      editItem: {
        followType: '',
        followDate: new Date(),
        followIsOrder: '',
        followOrderDate: '',
        followPredictDate: '',
        followNextDate: '',
        followRemark: '',
        // 联系人信息
        contactList: [],
      },
      contactInfo: {
        contactIndex: 0,
        contactName: '',   // 联系人名称
        contactGender: '', // 联系人性别
        contactBirthday: '', // 联系人生日
        contactPhone: '',   // 联系人电话
        contactQQ: '',  // 联系人QQ
        contactWechat: '', // 联系人微信
        contactWW: '',   // 联系人旺旺
        contactEmail: '', //联系人邮箱
        contactSalesQQ: '', // 联系对接业务员QQ
        contactSalesWechat: '', // 对接业务员微信
        contactSalesWW: '', // 对接业务员旺旺
        contactSalesEmail: '', // 对接业务员邮箱
        contactIdentityZ: '', // 身份证正面
        contactIdentityF: '', // 身份正反面
        contactTagList: [],
        selectTagList: [],
      },
      // 上门拜访
      // 电话拜访
      // QQ联系
      // 邮件联系
      // ComeVisit
      // PhoneVisit
      // QQContact
      // MailContact
      followup:{
        followType:""
      },
      temp:"m-input-required",
      followTypeOptions: [
        
        {
          key: 'ComeVisit',
          value: '上门拜访'
        },
        // {
        //   key: 'PhoneVisit',
        //   value: '电话拜访'
        // },
        // {
        //   key: 'QQContact',
        //   value: 'QQ联系'
        // },
        // {
        //   key: 'MailContact',
        //   value: '邮件联系'
        // },
        // {
        //   key: 'WeChatContact',
        //   value: '微信联系'
        // }
      ],
      orderOptions: [
        {
          key: 1,
          value: '是'
        },
        {
          key: 0,
          value: '否'
        }
      ],
      genderOptions: [
        {
          key: 1,
          value: '男'
        },
        {
          key: 0,
          value: '女'
        }
      ],
      initTagList: [],
      editableTabsValue: '1',
      uploadNum:0,
      requiredData: {},
      scoreData: {},

      CanSign: false,
      CanPersonalitySign: false,
      initId: '',

      saveLoading: false,//按钮加载圈
      // selectTagList: [],
    }
  },
  watch: {
    // '$route'(route) {
    //   const {id} = route.query
    //   const initId = this.initId
    //   if (id === initId) return
    //   const {name} = route
    //   if (name !== 'followClient') return
    //   this.onRefData()
    //   this.onInit()
    // }
  },
  methods: {
    // 获取联系人数据
    // 获取数据
    // 标签 公司属性
    async getAllTagAndAttribute() {
      return await getTagAndAttribute()
    },
    async getData() {
      const {query: {id}} = this.$route
      const params = {
        CompanyID: id,
        IsEdit: true
      }
      this.id = id
      this.initId = id
      return await getClientContactInfo(params)
    },
    async geFollowUp() {
      const {query: {id}} = this.$route
      const params = {
        Id: id
      }
      // this.id = id
      // this.initId = id
      return await getFollowUpInfo(params)
      
    },
    // 存储数据
    async initTagAndAttribute() {
      const data = await this.getAllTagAndAttribute()

      const tagList = data.filter(item => item['DictItemType'] === 'Lable')
      // 储存原始数据
      this.initTagList = this.setTagAndAttributeList(tagList)

    },
    setTagAndAttributeList(list) {
      // 整理数据
      return list.map((item, index) => Object.assign({}, {
        indexId: (index + 1),
        itemId: item['DictItemId'],
        itemName: item['DictItemName'],
        itemType: item['DictItemType'],
        itemCode: item['DictItemCode'],
        isRequired: initRequiredCodes.includes(item['DictItemCode']),
        isScores: initScoresCodes.includes(item['DictItemCode']),
            Multiple: item['Multiple'],
        lines: item['DictItemLines'].map(line => Object.assign({}, {
          itemId: item['DictItemId'],
          itemName: item['DictItemName'],
          lineId: line['DictItemLineId'],
          lineName: line['DisctItemLineName'],
          lineCode: line['DisctItemLineCode']
        }))
      }))
    },

    // 操作
    // 联系人
    onAddContact() {
      const contactInfo = JSON.parse(JSON.stringify(this.contactInfo));
      const [...contactList] = this.editItem.contactList

      contactList.push(contactInfo)

      this.onFormatContact(contactList)
    },
    onDeleteContact(targetName) {
      const length = this.editItem.contactList.length
      if (length === 1) {
        this.$message.warning('联系人需要填写，不能全部删除')
        return false
      }
      const [...contactList] = this.editItem.contactList.filter(item => item.contactIndex !== targetName)
      this.onFormatContact(contactList)
    },
    onFormatContact(list) {

      list.forEach((item, index) => {
        item.contactIndex = (index + 1).toString()
      })
      this.editableTabsValue = (list.length).toString()
      this.editItem.contactList = list

    },

    // 设置数据
    async setData() {
      await this.initTagAndAttribute()
      const [...initTagList] = this.initTagList
      const tagChoiceList = ['CustomLableExpress', 'CustomLablePersonality']


      // 处理原始标签数据
      this.contactInfo.contactTagList = initTagList.map(line => {
        return {
          id: line.itemId,
          name: line.itemName,
          code: line.itemCode,
          // selectValue: tagChoiceList.includes(line.itemCode) ? [] : '',
          // isChoice: tagChoiceList.includes(line.itemCode),
          isRequired: line.isRequired,
          isScores: line.isScores,
          selectValue:  line.Multiple?[]:"",
           Multiple: line.Multiple,
          isChoice: tagChoiceList.includes(line.itemCode),
          lines: line.lines.map(m => Object.assign({}, {
            key: m.lineCode,
            value: m.lineName
          }))
        }
      })

      const data = await this.getData()

      const followdata=await this.geFollowUp();
     
      this.followTypeOptions=followdata.FollowUpInfo
    
      const {
        CanSign,
        CanPersonalitySign,
        CusterContacts,
      } = data
      this.CanSign = CanSign ?? false
      this.CanPersonalitySign = CanPersonalitySign ?? false
      if (CusterContacts.length === 0) {
        this.onAddContact()
      } else {
        let contactList = []
        // const testArr = (code, value) => {

        //   if (tagChoiceList.includes(code)) {
        //     return value ? value.split(',') : []
        //   } else {
        //     return value
        //   }
        // }
        
        // const testArr = (value) => {
        //   const newValue = value.split(',')
        //   if (newValue.length >= 2) {
        //     return newValue
        //   } else {
        //     return value
        //   }
        // }
        const testArr = (value) => {
          if (!value) return []
          if (value.length === 0) return []
          const newValue = value.split(",");
          return newValue;
        }
        // const newData = []
        // CusterContacts.reduce((acc, cur) => {
        //   let targetItem = {}
        //   Object.entries(followContactMap).forEach(([key, src]) => {
        //     if (key !== 'contactTagList') {
        //       targetItem[src] = cur[src]
        //     } else {
        //       targetItem.CompanyLabelLines = cur['CustomerDictItems'].map(item => {
        //         const selectItems = item['DictItemLines'].filter(k => k['DisctIteSelect'])
        //         let selectValue = []
        //         if (selectItems.length) {
        //           selectValue = selectItems.map(m => m['DisctItemLineCode'])
        //         }
        //         return {
        //           ItemCode: item['DictItemCode'],
        //           ItemValue: selectValue.join(',')
        //         }
        //       })
        //     }
        //   })
        //   acc.push(targetItem)
        //   return acc
        // }, targetItem)


        // newData.reduce((acc, cur) => {
        //   let targetItem = {}
        //   Object.entries(followContactMap).forEach(([key, src]) => {
        //     if (key !== 'contactTagList') {
        //       targetItem[key] = cur[src]
        //     } else {
        //       targetItem.selectTagList = cur[src].map(item => {
        //         return {
        //           code: item.ItemCode,
        //           selectValue: testArr(item.ItemCode, item.ItemValue)
        //         }
        //       })
        //     }
        //   })
        //   acc.push(targetItem)
        //   return acc
        // }, contactList)
        // 处理联系人标签

        CusterContacts.reduce((acc, cur) => {
          let targetItem = {}
          Object.entries(followContactMap).forEach(([key, src]) => {
            if (key === 'contactGender') {
              targetItem[key] = cur[src] ? 1 : 0
            } else if (key !== 'contactTagList') {
              targetItem[key] = cur[src]
            } else {
              targetItem.selectTagList = cur[src].map(item => {
                return {
                  code: item.ItemCode,
                  selectValue: item.ItemValue
                }
                
              })
            }
          })
          acc.push(targetItem)
          return acc
        }, contactList)
        contactList.forEach(item => {
          const [...contactTagList] = this.contactInfo.contactTagList.map(
            item => Object.assign({}, item)
            );
          const [...selectTagList] = item.selectTagList.map(item =>
           Object.assign({}, item)
           );
          item.contactTagList = this.onMergeArr(contactTagList, selectTagList)
          // 多选时将字符串值变成数组，否则选中框不会显示值
          item.contactTagList.forEach(a=>{
            if(a.Multiple){
               a.selectValue=testArr(a.selectValue)

            }
          })
        })
        await this.onFormatContact(contactList)
      }
    },

    // 合并数组
    onMergeArr(targetData, paramsData) {
      return paramsData.reduce((acc, cur) => {
        const target = acc.find(e => e.code === cur.code)
        if (target) {
          Object.assign(target, cur)
        }
        return acc
      }, targetData)
    },

    // 储存数据
    async setParams() {
      const {editItem, editableTabsValue, id} = this
      const {contactList} = editItem
      const params = {}

      Object.entries(followMap).forEach(([key, src]) => {
        params[src] = editItem[key]
      })

      params.FollowUpType=this.followup.followType

      params.CompanyID = id

      params.ContactLines = this.editItem.contactList //contactInfo

      params.InquireData = this.itemSource
      params.InquirePhoto = this.InquireImage

      return await this.mapPostData(params)
      // return params
    },
    async mapPostData(params) {
      const {editableTabsValue} = this
      const {ContactLines} = params

      if (!ContactLines || ContactLines.length === 0)
        return params
      let index = 0;
      const contacts = ContactLines.map(a => {
        const {contactTagList, contactGender } = a;
        if (!contactTagList || contactTagList.length === 0) return a;
        const ContactLabels = contactTagList.map(t => {
          return {ItemCode: t.code, ItemValue: t.selectValue, Flag: t.flag}
         
        })

        index++;
        ContactLabels.forEach(item => {
          item.ItemValue=item.ItemValue instanceof Array
                        ? item.ItemValue.join(",")
                        : item.ItemValue
        });
        const gender = contactGender === 1;
        return {...a, ContactLabels, IsSelect: index === Number(editableTabsValue), contactGender: gender }
      })
      return {...params, ContactLines: contacts}
    },
    async followUPSubmit(){
      if (this.uploadNum > 0) {
        this.InquireImage = []
        this.$refs.uploadImgs.submit() // 实际执行的时图片上传方法 uploadHandel
      }else{
        this.onSave()
      }
    },
    async IsOrder(data)
    {

        if(data.value==='否')
        {
          this.temp=""
        }
        else
        {
          this.temp="m-input-required"
        }
    },
    async onSave() {
      
      this.saveLoading = true
      const params = await this.setParams()

      const imgRemarks = this.imgRemarkList
      const imgRemarkList = imgRemarks.map(a => a.val)
      
  
      // if(params.FollowUpContent.length>200)
      // {
      //     this.$message.warning('跟进内容字数过多');
      //     return
      // }

      let id = 0
      try {

        id = await editFollowClientInfo({ ...params, imgRemarkList })
      } catch (e) {
        this.saveLoading = false
      }
      if (!id) return
      this.$message.success('跟进成功，即将跳转列表页')
      this.$router.push({
        name: 'clientList'
      })
      await this.$store.dispatch('tagsView/delView', this.$route)
    },
    // 点击 导入Excel 按钮
    ImportExcel() {
      this.showInquireTable = true;
     this.dialogVisible=false;
    },
    // 点击 导入图片 按钮
    ImportImgs() {
      this.showInquireTable = false;

      this.itemSource = [];
    },
    beforeExcel() {

    },
    //上传excel并解析
    uploadExcel(params) {
      this.itemSource = [];
      const _file = params.file;
      if (_file.type == 'application/vnd.ms-excel' || _file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const fileReader = new FileReader();
        fileReader.onload = (ev) => {
          try {
            const data = ev.target.result;
            const workbook = XLSX.read(data, {
              type: 'binary'
            });
            let execelData = [];
            for (let sheet in workbook.Sheets) {
              //循环读取每个文件
              const sheetArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
              //若当前sheet没有数据，则continue
              if (sheetArray.length === 0) {
                continue;
              }


              for (let item in sheetArray) {
                let rowTable = {};
                //这里的rowTable的属性名注意要与上面表格的prop一致
                //sheetArray的属性名与上传的表格的列名一致
                if (sheetArray[item].规格型号 != null && sheetArray[item].规格型号 != '') {
                  rowTable.SpeciModel = sheetArray[item].规格型号;
                  rowTable.Pack = sheetArray[item].封装;
                  rowTable.Brand = sheetArray[item].品牌;
                  rowTable.Qty = sheetArray[item].数量;
                  rowTable.Quote = sheetArray[item].采购报价;
                  rowTable.Timequotes = sheetArray[item].报价时间;
                  execelData.push(rowTable)
                }
              }
            }
            this.itemSource = execelData;
          } catch (e) {
            this.$message.warning('文件类型不正确！');
          }
        }
        fileReader.readAsBinaryString(_file);
      } else {
        this.$message.warning('文件类型不正确！');
      }
      ;
      this.$refs.uploadExcel.clearFiles()
      this.ImportExcel()
    },
    exceedExcel: function () {
      this.$message.error("最多只能上传1个文件");
    },
    handleBeforeRemove(file, fileList) {
      const { uid } = file
      const index = this.imgRemarkList.findIndex(a => a.uid === uid)
      if (index >= 0) {
        this.imgRemarkList.splice(index, 1)
      }
    },
    //删除图片
    handleRemove(file, fileList) {
      this.uploadNum = fileList.length
    },
    //点击预览图查看大图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    exceedImg: function () {
      this.$message.error("最多只能上传20张图片");
    },
    handleAvatarSuccess(res, file) {	//res即为返回的图片路径
      if (res != null) {
        // this.$refs.videoFrom.validate(function(valid) {
        // if(valid) {
        // 自己的路径绑定变量=res;
        // //这里面写自己的提交方法
        // }
        // })
      }
    },
      // 上传选择图片改变事件
    handFileChange(files, fileList) {
      const { uid } = files
      this.uploadNum = fileList.length
      this.imgRemarkList.push({ uid, val: '' })
    },
    uploadImgs(files, uploadImgList) {
      this.showInquireTable = false;

    //   const _file = file;
    //   console.log(_file)
    //   if (_file.type.indexOf('/png') > 0 || _file.type.indexOf('/jpg') > 0 || _file.type.indexOf('/jpeg') > 0) {
    //     console.log('aaa')
    //   } else {
    //     this.$message.warning('只支持.png，.jpg，.jpeg格式的图片');
    //     this.$refs.uploadImgs.clearFiles()
    //   }
      this.upEvent(files.file)

    },
    async upEvent(file) {
      const formData = new FormData()
      formData.set('file', file)

      const res = await OnUploadPicture(formData)



      const urlOrign = this.$originUrl()
      const fullUrl = urlOrign + res

      this.InquireImage.push({ FileName: file.name,
        FilePath: fullUrl })

      if (this.InquireImage.length === this.uploadNum) { this.onSave() }
    },
    testHunder() {

    },
    // 联系人标签点击+1按钮
    async LableAddOne(item, contactId) {
      const {editItem} = this
      const {contactList} = editItem
      const newContactList = [...contactList]
      if (item.selectValue !== null && item.selectValue != '') {
        const contactEntity = newContactList.find(a => Number(a.ContactId) === Number(contactId))
        if (contactEntity != null) {
          const contactTagItem = contactEntity.contactTagList.find(a => a.code === item.code)
          if (contactTagItem != null) {
            contactTagItem.flag = true
            contactTagItem.isClickAdd = true
            editItem.contactList = newContactList
          }
        }

        // if(item.code==='ClientCharacter'){
        //    this.CanPersonalitySign=false
        // }else{
        //    this.CanSign=false
        // }
      } else {
        this.$message.warning('不能选择空值')
      }
    },
    onInit() {
      const formData = setRequiredOrScore()
      this.setData()

      this.requiredData = formData.requiredData
      this.scoreData = formData.scoresData
    },

    onRefData() {
      Object.assign(this.$data.editItem, this.$options.data().editItem)
      this.$refs.followIsOrder_ref.clear()
      this.$refs.followType_ref.clear()

    }
  },
  created() {
    this.onInit()
  }
}
</script>
<style lang="scss">
.p-follow-client {
  box-sizing: border-box;
  padding: 20px;
  width: 1370px;

  .t-c {
    text-align: center;
  }

  .znl-border-input {
    margin-right: 10px;

    .znl-input-title {
      color: #888888;
    }

    .znl-input-date {
      border: none
    }
  }

  .p-follow-client_box {
    margin-bottom: 10px;
  }

  .p-client-edit-contacts {
    margin-top: 20px;
    width: 100%;
    padding: 0;
    margin-bottom: 10px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  .p-client-edit-inquire {
    .header {
      display: flex;
    }

    margin-top: 20px;

    .inquire-table,
    .inquire-imgs {
      height: 200px;
      margin: 20px 0;
    }

    .upload-excel {
      display: inline-block;
    }

    .inquire-btn-importimg {
      height: 32px;
      margin-left: 10px;
    }
  }

  .m-select-input-score {
    display: inline-block !important;
    position: relative;

    .el-input__suffix {
      right: 35px;
    }

    .m-select-span-score {
      font-size: 12px;
      color: #0a76a4;
      position: absolute;
      top: 10px;
      right: 35px;
    }

    .m-select-span-add {
      position: absolute;
      top: 7px;
      right: 19px;
      color: #0a76a4;
      cursor: pointer;
    }
  }

  .m-select-attrinput-score {
    display: inline-block !important;
    position: relative;

    .el-input__suffix {
      right: 15px;
    }

    .m-select-span-score {
      font-size: 12px;
      color: #0a76a4;
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }

  // 公用
  .c-client-edit--ul {
    .c-client-edit--li-title {
      text-align: center;
      border-bottom: 1px solid #dedcdc;
      padding: 5px;
      color: #0a76a4;
      margin-bottom: 10px;
    }

    .c-client-edit--li {
      margin-bottom: 15px;
    }

    .c-client-edit--li-checkbox {
      display: flex;
      margin-bottom: 15px;
      align-items: center;

      .c-client-edit--li-checkbox--span {
        width: 115px;
        font-size: 12px;
        color: #888888;
        font-weight: bold;
        vertical-align: middle;
      }
    }
  }

  // element 组件修改
  .el-tabs--border-card {
    box-shadow: none;
  }
}
.imgstyle{
 margin-left: 30px;
}
.followbtn{
  display:block;margin:0 auto
}
</style>



